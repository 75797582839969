import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class AddressBookService {

      #api;
     
      constructor(){
            this.#api = `${API_URL}user/address-book`
      }
      

      paginate(data, index = 1) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
      }

      all() {
            let url = `${this.#api}/get/all`
            return apiService.get(url);
      }

      update(id, data) {
            let url = `${this.#api}/${id}`
            return apiService.put(url, data);

      }

      create(data) {
            let url = `${this.#api}`
            return apiService.post(url, data);

      }

      show(id) {
            let url = `${this.#api}/${id}`
            return apiService.get(url);
      }

      delete(id) {
            let url = `${this.#api}/${id}`
            return apiService.delete(url);
      }
}
