<template>
  <v-dialog v-model="dialog" width="900px">
    <v-card>
      <v-toolbar dark>
        <v-card-title class="headline">
          {{ title }} contact
          <hr />
        </v-card-title>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon @click="cancelForm">
            <i class="fas fa-times"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <div class="row">
            <div class="col-md-4 form-group">
              <v-text-field
                outlined
                dense
                :error="$v.address_book.first_name.$error"
                v-model="address_book.first_name"
              >
                <template v-slot:label>
                  First name
                  <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.address_book.first_name.$error"
                >This information is required.</span
              >
            </div>
            <div class="col-md-4 form-group">
              <v-text-field
                label="Middle name"
                outlined
                dense
                v-model="address_book.middle_name"
              ></v-text-field>
              <!-- </v-text-field>
              <span class="text-danger" v-if="$v.address_book.middle_name.$error"
                >This information is required.</span
              > -->
            </div>
            <div class="col-md-4 form-group">
              <v-text-field
                label="Last name"
                :error="$v.address_book.last_name.$error"
                outlined
                dense
                v-model="address_book.last_name"
              >
                <template v-slot:label>
                  Last name
                  <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.address_book.last_name.$error"
                >This information is required.</span
              >
            </div>

            <div class="col-md-4 form-group">
              <v-text-field
                outlined
                :error="$v.address_book.mobile.$error"
                dense
                v-model="address_book.mobile"
              >
                <template v-slot:label>
                  Mobile
                  <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.address_book.mobile.$error"
                >This information is required.</span
              >
            </div>

            <div class="col-md-4 form-group">
              <v-text-field outlined dense v-model="address_book.phone">
                <template v-slot:label>
                  Phone
                  <span class="text-danger"></span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.address_book.phone.$error"
                >This information is required.</span
              >
            </div>

            <div class="col-md-4 form-group">
              <v-text-field
                label="Work phone"
                outlined
                dense
                v-model="address_book.work_phone"
              >
              </v-text-field>
              <span class="text-danger" v-if="$v.address_book.work_phone.$error"
                >This information is required.</span
              >
            </div>
            <div class="col-md-6 col-sm-12 form-group">
              <v-text-field outlined dense v-model="address_book.occupation">
                <template v-slot:label> Occupation </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.address_book.occupation.$error"
                >This information is required.</span
              >
            </div>

            <div class="col-md-6 col-sm-12 form-group">
              <v-select
                outlined
                dense
                :error="$v.address_book.type.$error"
                v-model="address_book.type"
                :items="types"
                item-text="name"
                item-value="value"
              >
                <template v-slot:label>
                  Type <span class="text-danger"></span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.address_book.type.$error"
                >This information is required.</span
              >
            </div>
            <div class="col-md-12 col-sm-12 form-group">
              <v-text-field
                type="email"
                outlined
                dense
                :error="$v.address_book.email.$error"
                v-model="address_book.email"
              >
                <template v-slot:label>
                  Email <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.address_book.email.$error"
                >Enter a valid email.</span
              >
            </div>

            <div class="col-md-6 col-sm-12 form-group">
              <v-text-field
                outlined
                dense
                v-model="address_book.address_line_1"
              >
                <template v-slot:label>
                  Address line 1
                  <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span
                class="text-danger"
                v-if="$v.address_book.address_line_1.$error"
                >This information is required.</span
              >
            </div>

            <div class="col-md-6 col-sm-12 form-group">
              <v-text-field
                label="Address line 2"
                outlined
                dense
                v-model="address_book.address_line_2"
              >
              </v-text-field>
              <span
                class="text-danger"
                v-if="$v.address_book.address_line_2.$error"
                >This information is required.</span
              >
            </div>

            <div class="col-md-6 col-sm-12 form-group">
              <v-text-field
                label="Area"
                outlined
                dense
                v-model="address_book.area"
              >
              </v-text-field>
              <span class="text-danger" v-if="$v.address_book.area.$error"
                >This information is required.</span
              >
            </div>

            <div class="col-md-6 col-sm-12 form-group">
              <v-text-field
                outlined
                dense
                :error="$v.address_book.city.$error"
                v-model="address_book.city"
              >
                <template v-slot:label>
                  City <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.address_book.city.$error"
                >This information is required.</span
              >
            </div>

            <div class="col-md-6 col-sm-12 form-group">
              <v-text-field
                outlined
                dense
                :error="$v.address_book.district.$error"
                v-model="address_book.district"
              >
                <template v-slot:label>
                  District <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.address_book.district.$error"
                >This information is required.</span
              >
            </div>

            <div class="col-md-6 col-sm-12 form-group">
              <v-text-field
                :error="$v.address_book.state.$error"
                outlined
                dense
                v-model="address_book.state"
              >
                <template v-slot:label>
                  State <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.address_book.state.$error"
                >This information is required.</span
              >
            </div>

            <div class="col-md-12 form-group">
              <v-autocomplete
                outlined
                dense
                :error="$v.address_book.country_id.$error"
                v-model="address_book.country_id"
                :items="countries"
                item-text="title"
                item-value="id"
              >
                <template v-slot:label>
                  Country <span class="text-danger">*</span>
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.address_book.country_id.$error"
                >This information is required.</span
              >
            </div>
            <div class="col-md-4 form-group">
              <v-switch v-model="address_book.is_active" label="Status">
              </v-switch>
            </div>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn class="btn btn-standard cancel-btn" depressed @click="cancelForm"
          >Cancel
        </v-btn>
        <v-btn
          class="text-white ml-2 btn btn-primary"
          depressed
          @click="createOrUpdate"
          :loading="isLoading"
        >
          {{ edit ? "Update" : "Save" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { required, email } from "vuelidate/lib/validators";
import AddressBookService from "../../../../core/services/address/AddressBookService";
import CountryService from "@/core/services/address/CountryService";

const addressBook = new AddressBookService();
const country = new CountryService();

export default {
  name: "address-book",
  validations() {
    return {
      address_book: this.rules,
    };
  },

  data() {
    return {
      dialog: false,
      isLoading: false,
      edit: false,
      title: "",
      address_book: {
        first_name: "",
        middle_name: "",
        last_name: "",
        occupation: "",
        phone: "",
        mobile: "",
        email: "",
        address: "",
        area: "",
        city: "",
        district: "",
        state: "",
        country_id: "",
        is_active: true,
        type: "other",
      },
      countries: [],
      types: [
        {
          name: "Vendor",
          value: "vendor",
        },
        {
          name: "Other",
          value: "other",
        },
      ],
    };
  },

  computed: {
    rules() {
      let rule = {
        first_name: { required },
        middle_name: {},
        last_name: { required },
        phone: {},
        mobile: { required },
        email: { required, email },
        address: {},
        address_line_1: { required },
        address_line_2: {},
        area: {},
        city: { required },
        district: { required },
        state: { required },
        country_id: { required },
        work_phone: {},
        occupation: {},
        type: { required },
      };

      return rule;
    },
  },

  methods: {
    closeDialog() {
      this.dialog = false;
    },
    cancelForm() {
      this.resetForm();
      this.closeDialog();
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    createAddressBook() {
      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.title = "Add";
      this.getCountries();
    },
    editAddressBook(item) {
      this.openDialog();
      this.edit = true;
      this.address_book = item;
      this.title = "Edit";
      this.getCountries();
    },
    createOrUpdate() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      this.isLoading = true;
      addressBook
        .create(this.address_book)
        .then((response) => {
          this.$snotify.success("Contact added");
          this.resetForm();
          this.closeDialog();
          this.isLoading = false;
          this.$emit("refresh");
        })
        .catch((err) => {
          this.isLoading = false;
          this.errors = err.response.data.errors;
          this.$snotify.error("Oops something went wrong");
        });
    },
    update: function () {
      this.isLoading = true;
      addressBook
        .update(this.address_book.id, this.address_book)
        .then((response) => {
          this.$snotify.success("Contact updated");
          this.resetForm();
          this.closeDialog();
          this.isLoading = false;
          this.$emit("refresh");
        })
        .catch((err) => {
          this.isLoading = false;
          this.errors = err.response.data.errors;
          this.$snotify.error("Oops something went wrong");
        });
    },
    resetForm() {
      this.address_book = {
        first_name: "",
        middle_name: "",
        last_name: "",
        occupation: "",
        phone: "",
        mobile: "",
        email: "",
        address: "",
        address_line_1: "",
        address_line_2: "",
        area: "",
        city: "",
        district: "",
        state: "",
        country_id: "",
        is_active: true,
        type: "other",
      };
      this.errors = [];
      this.$v.$reset();
    },
    getCountries() {
      country
        .all()
        .then((res) => {
          this.countries = res.data;
        })
        .catch((err) => {});
    },
  },
};
</script>
  